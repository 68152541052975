import $ from 'jquery'
import React, { Component } from 'react'
import PackeryModule from 'react-packery-component'
import Dropdown from 'components/dropdown'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import Title from 'components/title'
import { getImageProportions, trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const Packery = PackeryModule(React)
const packeryOptions = {
  itemSelector: '.item-grid',
  percentPosition: true,
  gutter: '.gutter-sizer',
  transitionDuration: '0', //'0.4s'
}

const Collection = ({ collection, activeFilterValue }) => {
  const imageProportions = getImageProportions(collection.imageThumb)
  const imageProportionClass = imageProportions === 'wide' ? 'item-wide' : ''

  const showClass =
    activeFilterValue === collection.category.slug ||
    activeFilterValue === 'all'
      ? 'show'
      : 'hide'
  return (
    <Inview
      className={`item-grid trigger-in-view trigger-once fade zoom-img-on-hover ${imageProportionClass} ${showClass}`}
    >
      <LinkWrap to={`/collections/collections/${collection.slug}`}>
        <ImageWrap image={collection.imageThumb} />
      </LinkWrap>
      <h4>
        <LinkWrap
          to={`/collections/collections/${collection.slug}`}
          className="dark"
        >
          {collection.title}
        </LinkWrap>
      </h4>
    </Inview>
  )
}

const FeaturedCollection = ({ collection, data }) => {
  const designer = collection.designer
  const image = collection.imageMain
  const imageM = image
  // const image = collection.imageBlock
  // const imageM = collection.imageBlockMobile
  //   ? collection.imageBlockMobile
  //   : collection.imageBlock
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  return (
    <div className="col">
      <LinkWrap to={`/collections/collections/${collection.slug}`}>
        <div className="img-wrapper-container">
          <div className="dim-overlay" />
          <div className="img-wrapper-proportion hide-for-mobile object-fit-holder">
            <ImageWrap image={image} />
          </div>
          <div className="img-wrapper-proportion show-for-mobile object-fit-holder">
            <ImageWrap image={image} />
          </div>
        </div>
      </LinkWrap>
      <div className="overaly-title">
        <LinkWrap to={`/collections/collections/${collection.slug}`}>
          <div className="title">{collection.title}</div>
        </LinkWrap>
        {designer && ( // some collections have no designer
          <div className="designed-by">
            {`${tr('DESIGNED_BY')}${tr('COLON')}${tr('SPACE')}`}
            <LinkWrap to={`/collections/designers/${designer.slug}`}>
              {designer.title}
            </LinkWrap>
          </div>
        )}
      </div>
    </div>
  )
}

const ColcatSelector = ({
  setFilterValue,
  activeFilterValue,
  title,
  colcatSlug,
}) => {
  const selectedClass = activeFilterValue === colcatSlug ? 'selected' : ''
  return (
    <li>
      <a
        className={`ui ${selectedClass}`}
        onClick={setFilterValue}
        data-slug={colcatSlug}
      >
        {title}
      </a>
    </li>
  )
}

class CollectionsPage extends Component {
  constructor() {
    super()
    this.state = {
      activeFilterValue: 'all',
    }
    this.setFilterValue = this.setFilterValue.bind(this)
  }

  setFilterValue(e) {
    const dataSlug = e.target.getAttribute('data-slug')
    this.setState({ activeFilterValue: '' })
    setTimeout(() => this.setState({ activeFilterValue: dataSlug }))
  }

  shuffleGridPackery() {
    if (typeof window !== 'undefined') {
      var $g = $('.grid-packery')
      var $win = $(window)
      var random = function (a, b) {
        return a + Math.random() * (b - a)
      }

      var $its, $it
      $its = $g.find('.item-grid')
      if ($its.length > 0) {
        $its.each(function (i) {
          $it = $(this)
          if ($win.width() > 767) {
            var y = parseInt($it.css('top'))
            if (y >= 10) {
              var mTop = random(0, 5) + '%'
              var mLeft = random(0, 6) > 3 ? random(0, 20) + '%' : '0%'
              $it.css({
                'margin-top': mTop,
                'margin-left': mLeft,
              })
            } else {
              $it.css({
                'margin-top': '0%',
                'margin-left': '0%',
              })
            }
          } else {
            $it.css({
              'margin-top': '0%',
              'margin-left': '0%',
            })
          }
        })
      }
    }
  }

  componentDidMount() {
    this.shuffleGridPackery()
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const page = this.props.data.thisPage
    const featuredCollections = this.props.data.featuredCollections
    const allCollections = this.props.data.allCollections
    const collectionCategories = this.props.data.collectionCategories.edges
    const defaultFilter = [
      { node: { title: tr('ALL_COLLECTIONS'), slug: 'all' } },
    ]
    const filters = defaultFilter.concat(collectionCategories)

    return (
      <Layout>
        <div id="content">
          <Title
            title={page.title}
            subtitle={tr('COLLECTIONS')}
            subtitleLink="/collections"
          />
          <section className="page-content">
            <div className="featured-collections">
              {featuredCollections.edges.map((featuredCollection, i) => (
                <FeaturedCollection
                  key={i}
                  collection={featuredCollection.node}
                  data={this.props.data}
                />
              ))}
            </div>
            <div className="wrap">
              <ul className="collections-categories">
                {filters.map((filter, i) => {
                  return (
                    <ColcatSelector
                      key={i}
                      setFilterValue={this.setFilterValue}
                      activeFilterValue={this.state.activeFilterValue}
                      title={filter.node.title}
                      colcatSlug={filter.node.slug}
                    />
                  )
                })}
              </ul>
              <div className="collections-categories-mobile-container">
                <Dropdown
                  filters={filters}
                  setFilterValue={this.setFilterValue}
                  activeFilterValue={this.state.activeFilterValue}
                  tr={tr}
                />
              </div>
              <div className="grid-collections-container">
                <Packery
                  className={'grid-packery grid-packery-disorder'}
                  options={packeryOptions}
                >
                  <div className="gutter-sizer" />
                  {allCollections.edges.map((collection, i) => (
                    <Collection
                      key={i}
                      collection={collection.node}
                      activeFilterValue={this.state.activeFilterValue}
                    />
                  ))}
                </Packery>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default CollectionsPage

export const collectionsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allCollections: allContentfulCollections(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            title
            slug
          }
          imageThumb {
            ...GatsbyImage
          }
        }
      }
    }
    featuredCollections: allContentfulCollections(
      sort: { fields: [orderBlocks], order: ASC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          designer {
            title
            slug
          }
          imageMain {
            ...GatsbyImage
          }
          imageBlock {
            ...GatsbyImage
          }
          imageBlockMobile {
            ...GatsbyImage
          }
        }
      }
    }
    collectionCategories: allContentfulCollectionCategories(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "collections" }) {
      title
      slug
    }
  }
`
